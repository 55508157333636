@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Oleo+Script&family=PT+Serif&family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@500&display=swap');

html, body {
  background: rgb(255,198,37);
  background: radial-gradient(circle at bottom, rgba(255,198,37,1) 0%, rgba(244,216,53,1) 40%, rgba(171,25,249,1) 100%);
  background-attachment: fixed;
}

body {
  overflow-y: scroll;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Roboto Slab', serif;
  margin: 0;
}

p {
  margin-top: 0;
  font-size: 22px;
}

p, text, textarea, ol, li, ul {
  font-family: 'Poppins', sans-serif;
  text-align: justify;
}


h1 {
  font-size: 50px;
  font-family: 'Roboto Slab', serif;
}

h2 {
  font-size: 40px;
}