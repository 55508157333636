
.cursor-container {
  width: 50px;
  height: 50px;
  display: grid;
  place-items: center;
  border-radius: 10px;
  
  &:hover {
    background-color: #fffa;
  }
  
  .cursor-icon {
    width: 80%;
    height: 80%;
  }
}