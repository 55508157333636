.gallery {
  box-sizing: border-box;

  .scrollable {
    width: 100%;
    height: 200px;

    .gallery-thumbnails {
      display: flex;
      align-items: center;
      height: 100%;
      gap: 20px;
      box-sizing: border-box;


      .gallery-thumbnail {
        min-height: 180px;
        min-width: 180px;

        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        opacity: 0.8;
        border-radius: 10px;

        &:hover {
          opacity: 1;
          box-shadow: 5px 5px 5px rgba(0,0,0,0.2);
        }
      }
    }
  }

  .gallery-view {
    width: 100%;
    box-sizing: border-box;
    text-align: justify;

    h2 {
      font-weight: 400;
      font-size: 40px;
      font-family: 'Oleo Script', cursive;
    }

    p {
      margin-top: 3px;
      margin-bottom: 3px;
      text-align: justify;
    }

    img {
      margin: 7px;
    }
  }
}