.project-container {
  position: relative;
  width: min(100%, 1200px);
  margin: 0 auto 0;
  min-height: 100vh;
  background-color: #fffa;
  padding: 40px;
  box-sizing: border-box;
  padding-bottom: 200px;
}

@media only screen and (max-width: 1400px) {
  body {
    zoom: 80%;
  }
}