.compliment-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 50px;
  box-sizing: border-box;

  h3 {
    margin: 0;
  }

  textarea {
    width: 80%;
    height: 100px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    resize: none;
    border-radius: 10px;
    font-size: 20px;
    border: 2px solid #ccc;
    padding: 5px;
  }

  input {
    width: 80%;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    border-radius: 10px;
    font-size: 20px;
    border: 2px solid #ccc;
    padding: 5px;
  }

  .compliment-submit {
    padding: 10px 20px 10px 20px;
    border-radius: 10px !important;
    outline: 2px solid #ccc;
    font-size: 18px;
    font-weight: 600;
    color: #666;

    &:hover {
      background: rgb(255,198,37);
      background: linear-gradient(55deg, rgba(255,198,37,1) -20%, rgba(244,216,53,1) 36%, rgba(171,25,249,1) 120%);
      outline: none;
      color: #fff;

    }
  }
}