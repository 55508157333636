.dlc-container {
  position: fixed;
  left: calc(50% - min(100%, 1200px)/2);
  bottom: 0;
  display: flex;
  width: min(100%, 1200px);
  gap: 20px;
  padding: 20px;
  box-sizing: border-box;
  justify-content: center;
  background: linear-gradient(to top, rgba(253, 253, 253, 0.667), #0000 100%);

  .dlc-icon {
    position: relative;
    width: 200px;
    height: 120px;
    border-radius: 10px;
    overflow: hidden;
    display: grid;
    place-items: center;
    text-align: center;

    h3 {
      color: #fff;
      font-size: 22px;
      z-index: 10;
      text-shadow: 3px 3px 5px rgba(0,0,0,0.8);
    }

    &:hover {
      box-shadow: 3px 3px 5px rgba(0,0,0,0.3);

      > .dlc-locked {
        background-image: none;
      }

      > h3 {
        z-index: 10 !important;
      }
    }

    .dlc-locked {
      position: absolute;
      top: 0;
      left: 0;
      width: inherit;
      height: inherit;
      background-color: rgb(0, 0, 0);
      background-image: url(../../images/padlock.png);
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      opacity: 0.8;
    }
  }
}