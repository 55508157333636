.lootbox-container {
  position: fixed;
  width: 200px;
  left: calc(50% + 600px);
  top: calc(50% - 300px);

  .lootbox-icon {
    position: relative;
    display: grid;
    place-items: center;
    width: 100%;
    height: 200px;

    .lootbox {
      position: absolute;
      background-size: contain;
      background-position: center;
      width: 100%;
      height: 200px;
      display: grid;
      place-items: center;
    }

    .lootbox-timer {
      color: #fff;
      z-index: 10;
      font-size: 50px;
    }
  }

  .cursors-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
  }
}